html,
body {
  height: 100%;
}

body {
  display: -ms-flexbox;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f5f5f5;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.orderable-element {
  position: relative;
  margin: 5px 20px 15px 5px;
}

.orderable-element>div {
  display: inline-block;
  vertical-align: middle;
}

.orderable-element .photo-preview {
  width: 60px;
  position: relative;
  margin-right: 40px;
}

.orderable-element p {
  font-size: 14px;
  margin-bottom: 2px;
}

.orderable-element input {
  width: 400px;
  font-size: 12px;
  display: inline-block;
}

.orderable-element>div>span {
  position: absolute;
  top: -20px;
  right: -17px;
  color: #e60505;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
}

.orderable-element>div>i {
  position: absolute;
  top: 0px;
  right: -17px;
  color: green;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
}

.tabs {
  display: block;
  margin: 20px 0 10px;
  padding: 0;
  list-style: none;
  border-bottom: 1px solid #dee2e6;
}

.tabs li {
  display: inline-block;
  font-weight: bold;
  padding: 10px 14px;
  cursor: pointer;
}

.tabs li.active {
  border-bottom: 4px solid #ffc107;
}

.tabs li i {
  background: #26b83a;
  color: #fff;
  display: inline-block;
  border-radius: 50%;
  font-size: 9px;
  font-weight: normal;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
}

#products-table input {
  outline: none;
  border: 1px solid #ffc107;
  width: 100px;
  padding: 3px 10px;
}

#products-table i {
  display: block;
  font-size: 12px;
  margin-top: 5px;
}

.bottom-panel {
  position: fixed;
  width: 100%;
  height: 65px;
  background: #fff;
  bottom: 0;
  left: 0;
  display: none;
}

.bottom-panel.active {
  display: block;
}

.bottom-panel-control {
  float: right;
  margin-right: 60px;
  margin-top: 15px;
}

.bottom-panel-control > button {
  margin-left: 20px;
}

.container {
  margin-top: 10px;
  padding: 30px 30px;
  background: #fff;
}

.custom-tooltip {
  padding: 4px;
  background: #18b626;
  border: none;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  line-height: 8px;
  font-size: 12px;
  margin-left: 6px;
}

.product-from h5 {
  margin-bottom: 20px;
}

.flash-message {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 40px;
  opacity: 0.8;
  line-height: 40px;
  width: 100%;
  z-index: 1;
}

.flash-message.success {
  background: #00a651;
}

.flash-message.error {
  background-color: #cc2424;
}

.flash-message>div {
  width: 1140px;
  margin: 0 auto;
  color: #fff;
  font-weight: bold;
}

.flash-message p {
  margin-bottom: 0;
  font-size: 14px;
}

.copy-photo,
.delete-photo {
  color: #6971d9;
  margin-left: 8px;
  border: 1px solid #6971d9;
  padding: 3px;
  font-size: 19px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 2px;
}

.delete-photo {
  color: #cc2424;
  border: 1px solid #cc2424;
}

.options {
  position: relative;
  cursor: pointer;
}

.options ul {
  display: none;
  position: absolute;
  list-style: none;
  top: 24px;
  right: 0;
  background: #fff;
  padding: 0;
  width: 226px;
  border: 1px solid #dee2e6;
  z-index: 1;
}

.options ul.active {
  display:block;
}

.options ul>li {
  padding: 8px 10px;
  text-indent: 10px;
}

.options ul>li a {
  color: #000;
}

.options ul>li:hover {
  background: #ffc107;
  color: #fff;
}

.options ul>li:hover a {
  color: #fff;
  text-decoration: none;
}

.table-responsive {
  overflow: visible;
}

.photo-preview .thumbnail {
  cursor: pointer;
}

.photo-preview .thumbnail img {
  width: 60px;
}












